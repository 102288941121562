// eslint-disable-next-line spaced-comment
/// <reference types="stripe-v3" />
/* global Stripe */
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { firstValueFrom, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Plan } from '@ss/typings/nta-hunter';

import { StripeCustomer, StripeSource } from '@ss/typings/stripe';

import { firebaseConfig, stripeKey } from '@ss/typings/config';

@Injectable({ providedIn: 'root' })
export class PaymentApi {

	elements: any;

	readonly url = `${firebaseConfig.functionsUrl}/stripeApi`;

	private stripe = Stripe(stripeKey);

	constructor(private http: HttpClient) {
		this.elements = this.stripe.elements();
	}

	getCustomer(): Observable<StripeCustomer> {
		const url = `${this.url}/customer/`;

		return this.http.get<StripeCustomer>(url);
	}

	updateSource(card: any): Observable<StripeSource> {
		return from(this.stripe.createSource(card)).pipe(switchMap((data) => this.http.post<StripeSource>(`${this.url}/customer/source`, { sourceId: data.source.id })));
	}

	getPlanFromPriceId(priceId: string): Promise<Plan> {
		const url = `${this.url}/plan/${priceId}`;

		return firstValueFrom(this.http.get<Plan>(url));
	}
}
